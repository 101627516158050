import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"

import { seo_list } from "../../../components/SeoList"

const SeoArticle = ({ data }) => {
  const getFirstLetter = phrase => {
    for (var i = 0; i < phrase.length; i++) {
      if (phrase.charAt(i).match(/[a-z,A-Z,ą,ę,ł,ś,ż,ź,ć,Ą,Ę,Ł,Ż,Ź,Ć,Ś]/i)) return phrase.charAt(i)
    }
    return "Z"
  }

  let seo_list_formatted_sorted = []
  const alfabet_list = [...new Set(seo_list.map(term => getFirstLetter(term.name).toUpperCase()))].sort()
  alfabet_list.forEach(letter => {
    seo_list_formatted_sorted.push({
      letter,
      content: seo_list
        .filter(term => getFirstLetter(term.name).toUpperCase() === letter)
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    })
  })

  var counter = 0

  let increase_counter = () => {
    counter++
  }
  let reset_counter = () => {
    counter = 0
  }

  return (
    <Layout
      seo={{
        title: "Słownik SEO • Wyjaśniamy pojęcia z zakresu pozycjonowania • WeNet",
        description:
          "Poznaj listę pojęć z zakresu pozycjonowania i optymalizacji stron internetowych • Odkryj proste definicje napisane przystępnym językiem • Poszerz wiedzę z WeNet",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id":
                  "/baza-wiedzy/artykul/slownik-seo-pojecia-z-zakresu-pozycjonowania-i-optymalizacji-stron-internetowych/",
                name: "Słownik seo",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/slownik-seo-pojecia-z-zakresu-pozycjonowania-i-optymalizacji-stron-internetowych",
            text: "Słownik SEO – pojęcia z zakresu pozycjonowania i optymalizacji stron internetowych",
          },
        ]}
      />
      <Article img={<img src={data.file.childImageSharp.fluid.src} alt={"Słownik Seo"} />}>
        <h1 style={{ margin: "46px 0 24px 0", fontWeight: "light" }}>
          <strong>Słownik SEO</strong> – pojęcia z zakresu pozycjonowania i optymalizacji stron internetowych
        </h1>
        <hr />
        <p>
          Jeśli chcesz zadbać o wysoką pozycję swojej strony internetowej, musisz koniecznie zapoznać się z szeregiem
          pojęć z zakresu pozycjonowania i optymalizacji. Nie są to proste tematy i często sprawiają kłopot osobom
          początkującym. Jak odnaleźć się w gąszczu trudnych słów? Z pomocą przychodzi nasz słownik SEO.
        </p>
        <p>
          Dopiero rozpoczynasz przygodę z pozycjonowaniem? Koniecznie zapoznaj się z naszym obszernym słownikiem.
          Skomplikowane pojęcia przedstawiliśmy w formie krótkich, a zarazem treściwych definicji pisanych prostym i
          zrozumiałym językiem. Przyjazna forma sprawia, że nawet osoba początkująca bez trudu zrozumie nawet
          nieprzystępne na pierwszy rzut oka tematy.
        </p>
        <p className="inserted-seo-link">
          Dowiedz się,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            czym jest audyt SEO
          </a>{" "}
          strony internetowej.
        </p>
        <p>
          Wszystkie najważniejsze definicje zebraliśmy w jednym miejscu. Są tu zarówno pojęcia popularne, jak i te
          rzadziej spotykane. Wiele pojęć ma różne nazwy, a także polskie odpowiedniki, dlatego staraliśmy się
          uwzględnić wszystkie warianty. Ułożyliśmy je alfabetycznie, dlatego szybko odnajdziesz interesujący termin.
          Teraz z łatwością zapoznasz się z definicjami i śmiało wejdziesz do świata SEO.
        </p>
        <h2
          style={{
            marginTop: "40px",
            fontSize: "24px",
            color: "#601373",
            marginBottom: "20px",
          }}
        >
          Lista pojęć SEO
        </h2>
        <ul className="seo-topics-list">
          {seo_list_formatted_sorted.map((letter, letter_index) => (
            <React.Fragment key={letter_index}>
              {letter.content.map((elem, elem_index) => (
                <li key={elem_index}>
                  <span style={{ display: "none" }}>{increase_counter()}</span>
                  <a href={"#" + elem.href}>{elem.name}</a>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <ul className="letters-list">
              {seo_list_formatted_sorted.map((letter, index) => (
                <li key={index}>
                  <a href={"#" + letter.letter} role="button">
                    {letter.letter.toUpperCase()}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <p className="inserted-seo-link" style={{ paddingTop: "15px" }}>
          Wykonaj <a href="/">audyt SEO strony</a>. Ulepsz jej widoczność w wyszukiwarce.
        </p>

        {seo_list_formatted_sorted.map((letter, letter_index) => (
          <div id={letter.letter} ket={letter_index}>
            {letter.content.map((elem, elem_index) => (
              <>
                {counter === 9 && (
                  <div style={{ marginTop: "-20px", marginBottom: "-20px" }}>
                    <CallToAction
                      to={"/"}
                      text={
                        <>
                          Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce.{" "}
                          <span style={{ display: "inline-block" }}>Wykonaj teraz {">>"}</span>
                        </>
                      }
                    />
                  </div>
                )}
                {counter > 8 ? reset_counter() : increase_counter()}
                <div className="seo-topic" id={elem.href} key={elem_index}>
                  <h3>{elem.name}</h3>
                  {elem?.content}
                </div>
              </>
            ))}
          </div>
        ))}
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/slownik-seo-wide.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
